import React, { FC, useRef, useState } from "react";
import Validator from "simple-react-validator";
import { useSelector } from "react-redux";
import { errorFocus } from "../../../common/utils/function";
import { useForceUpdate } from "../../../common/hooks/useForceUpdate";
import { profileAccountService } from "../../../store/actions";

interface TransactionPrivateTagModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const TransactionPrivateTagModal: FC<TransactionPrivateTagModalProps> = ({
  show,
  onClose,
  onSuccess
}) => {
  const forceUpdate = useForceUpdate();
  const validator = useRef(
    new Validator({
      element: (message: string, className: string) => (
        <div className="red">{message}</div>
      ),
      autoForceUpdate: { forceUpdate },
    })
  );

  const _account = useSelector((e: Object) => (e as any)?.common?.account);
  

  const [txHash, setTxHash] = useState("");

  const [tag, setTag] = useState("");
  const signature = _account?.signature;
  const message = _account?.message;
  const handleSubmit = async () => {
    try {
      const data = {

        transaction:txHash,
          name : tag,
        message,
        signature,
      };
      const response = await profileAccountService.createTxPrivateNote(data);
      console.log(response);
      console.log("Form Data:", JSON.stringify(data));
      onSuccess();
    } catch (err) {
      console.log(err);
    }

    onClose();
  };

  if (!show) return null;

  const styles = {
    modalOverlay: {
      position: "fixed" as "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContent: {
      background: "#fff",
      padding: "20px",
      borderRadius: "8px",
      width: "400px",
      maxWidth: "90%",
      position: "relative" as "relative",
    },
    label: {
      display: "block",
      marginTop: "15px",
    },
    input: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      marginBottom: "10px",
      borderRadius: "4px",
      border: "1px solid #ccc",
    },
    textarea: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      marginBottom: "10px",
      borderRadius: "4px",
      border: "1px solid #ccc",
    },
    modalActions: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "10px",
    },
    cancelButton: {
      padding: "8px 12px",
      background: "#ddd",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    submitButton: {
      padding: "8px 12px",
      background: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.modalOverlay} onClick={onClose}>
      <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <h2>Add a New Private Transaction Tag</h2>

        <label style={styles.label}>Enter The Transaction Hash</label>
        <input
          type="text"
          placeholder="Enter an Tx Hash"
          value={txHash}
          onChange={(e) => setTxHash(e.target.value)}
          style={styles.input}
        />

        

        <label style={styles.label}>Private Tag</label>
        <textarea
          placeholder="Private Tag"
          maxLength={500}
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          style={styles.textarea}
        />
        <small>
          Character limit: {tag.length} / 500.
        </small>

        <div style={styles.modalActions}>
          <button onClick={onClose} style={styles.cancelButton}>
            Cancel
          </button>
          <button onClick={handleSubmit} style={styles.submitButton}>
            Create Private Transaction Tag
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionPrivateTagModal;
