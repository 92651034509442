import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { profileAccountService } from "../../../store/actions";
interface DeleteTagModalProps {
    show: boolean;
    onClose: () => void;
    tagItem: TransactionTag;
    onSuccess: () => void;
}
interface TransactionTag {
    tag_name: string;
    transaction_hash: string;
    id: string;
}



const DeleteModal:FC<DeleteTagModalProps> = ({show,onClose,tagItem,onSuccess}) => {
    const [hash, setHash] = useState(tagItem.transaction_hash);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const _account = useSelector((e: Object) => (e as any)?.common?.account);
    const signature = _account?.signature;
    const message = _account?.message;
    const handleSubmit = async () => {
        if (!tagItem.transaction_hash) {
          setError('Invalid address details');
          return;
        }
 
        try {
          setIsSubmitting(true);
          setError(null);
    
          await profileAccountService.deleteTransactionTag({
          tagId:tagItem.id,
            message,
            signature,
          });
    
          onSuccess();
          onClose();
        } catch (err) {
          console.error('Error updating tag:', err);
          setError('Failed to update tag. Please try again.');
        } finally {
          setIsSubmitting(false);
        }
    };
    if (!show) return null;

  const styles = {
    modalOverlay: {
      position: "fixed" as "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContent: {
      background: "#fff",
      padding: "20px",
      borderRadius: "8px",
      width: "400px",
      maxWidth: "90%",
      position: "relative" as "relative",
    },
    label: {
      display: "block",
      marginTop: "15px",
    },
    input: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      marginBottom: "10px",
      borderRadius: "4px",
      border: "1px solid #ccc",
    },
    error: {
      color: "red",
      marginBottom: "10px",
      fontSize: "14px",
    },
    modalActions: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "10px",
      marginTop: "20px",
    },
    cancelButton: {
      padding: "8px 12px",
      background: "#ddd",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    submitButton: {
      padding: "8px 12px",
      background: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      opacity: isSubmitting ? 0.7 : 1,
    },
  };
    
  return (
    <div style={styles.modalOverlay} onClick={onClose}>
      <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <h2>Confirmation Required</h2>
        <p>Are you sure you wish to remove the Transaction tag?</p>
              <h1>{tagItem.transaction_hash}</h1>

        {error && <div style={styles.error}>{error}</div>}

        <div style={styles.modalActions}>
          <button 
            onClick={onClose} 
            style={styles.cancelButton}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button 
            onClick={handleSubmit} 
            style={styles.submitButton}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Deleting' : 'Delete'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal